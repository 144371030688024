import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/estimate';

export default {
    all(payload) {
        return axios.post(`${RESOURCE_NAME}/all`, payload)
    },
    create(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload)
    },
    update(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
    remove(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, { "estimate_id": payload })
    },
    fill_from_default(payload) {
        return axios.post(`${RESOURCE_NAME}/fill-from-default`, payload)
    },
    fill_from_prev_year(payload) {
        return axios.post(`${RESOURCE_NAME}/fill-from-prev-year`, payload)
    },
    clear_current_year(payload) {
        return axios.post(`${RESOURCE_NAME}/clear-current-year`, payload)
    },
};
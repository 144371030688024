<template>
  <v-card elevation="0" tile flat class="fill-height" :class="personal ? 'px-10 pt-5' : 'px-10 pt-3'" min-height="280"
          :disabled="disabled">
    <div class="d-flex flex-wrap elevation-3 pa-3 align-center mb-3">
      <div style="font-size: 1.1rem; text-transform: uppercase;">
        {{ `Планові доходи та витрати на ${current_year} рік` }}
      </div>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-bind="attrs"
              v-on="on"
              class="mr-2"
          >
            {{ `${current_year} рік` }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item
              v-for="(item, index) in years"
              :key="index"
              @click="changeYear(item)"
          >
            <v-list-item-title>{{ item }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-bind="attrs"
              v-on="on"
              class="mr-2"
              icon
              v-if="!personal && !disabled"
          >
            <v-icon>
              mdi-dots-vertical
            </v-icon>
          </v-btn>
        </template>
        <v-list nav>
          <v-list-item dense @click="fillForDefault(true)">
            <v-list-item-icon>
              <v-icon color="success">mdi-format-list-bulleted</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle>
                Заповнити дохідну частину <br> стандартними статтями
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item dense @click="fillForDefault(false)">
            <v-list-item-icon>
              <v-icon color="success">mdi-format-list-bulleted</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle>
                Заповнити витратну частину <br> стандартними статтями
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item dense @click="fillForPrevYear(false)">
            <v-list-item-icon>
              <v-icon color="success">mdi-format-list-bulleted</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle>
                Заповнити усе <br> із попереднього місяця
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item dense @click="cleatCurrentYear(true)">
            <v-list-item-icon>
              <v-icon color="success">mdi-format-list-bulleted</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle>
                Очистити дохідну частину
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item dense @click="cleatCurrentYear(false)">
            <v-list-item-icon>
              <v-icon color="success">mdi-format-list-bulleted</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle>
                Очистити витратну частину
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <v-dialog v-model="show_edit" width="600">
      <v-card>
        <v-toolbar color="grey darken-3" dark elevation="1" height="50">
          <v-toolbar-title>
            {{ getEditTitle }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="12" v-if="edit_object.parent_name">
              <v-text-field
                  v-model="edit_object.parent_name"
                  hide-details
                  label="Батько"
                  filled
                  disabled
                  color="grey"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                  v-model="edit_object.name"
                  hide-details
                  label="Назва статі"
                  filled
                  :class="!edit_object.name ? 'req-star' : ''"
                  color="grey"
              />
            </v-col>
            <v-col cols="12" md="6">
              <date-component
                  label="Початок дії"
                  v-model="edit_object.date"
                  req
                  :class_="!edit_object.date ? 'req-star' : ''"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                  v-model.number="edit_object.repeat"
                  hide-details
                  filled
                  label="Кількість повторів (міс.)"
                  :class="!edit_object.repeat ? 'req-star' : ''"
                  color="grey"
                  v-decimal
              />
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                  v-model.number="edit_object.sum"
                  hide-details
                  filled
                  label="Вартість"
                  :class="!edit_object.sum ? 'req-star' : ''"
                  color="grey"
                  v-decimal
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed text color="secondary darken-1" @click.stop="saveChanges" class="button-accept">
            <v-icon left>mdi-content-save</v-icon>
            Зберегти
          </v-btn>
          <v-btn depressed text tile color="grey" @click="closeModal">
            <v-icon left>mdi-close</v-icon>
            Відмінити
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12">
        <v-tabs class="custom-tabs" color="success" v-model="tab">
          <v-tab>Доходи</v-tab>
          <v-tab>Витрати</v-tab>

          <v-tab-item class="pa-1">
            <v-btn outlined depressed color="success" class="mt-2" small @click="addProfitRow" v-if="!personal">Додати рядок</v-btn>
            <v-row class="mt-1">
              <v-col cols="12">
                <v-card class="d-flex flex-wrap flex-row row-card-header" elevation="0">
                  <v-col cols="12" sm="6" md="6" xl="6">
                    <div>Назва статі</div>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" xl="6">
                    <v-row>
                      <v-col cols="3">
                        <div>Початок дії</div>
                      </v-col>
                      <v-col cols="3">
                        <div>К-сть повторів</div>
                      </v-col>
                      <v-col cols="3">
                        <div>Вартість</div>
                      </v-col>
                      <v-col cols="3">
                        <div>Сума в місяць</div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-card>

                <template
                    v-for="item in profit"
                >
                  <template v-if="!item.children.length">
                    <v-card :key="item.id"
                            class="d-flex flex-wrap flex-row align-center row-card-body"
                            elevation="0"
                            :class="personal ? 'py-2' : ''"
                    >
                      <v-col cols="12" sm="6" md="6" xl="6" class="py-0 d-flex align-center">
                        <div>
                          <v-menu offset-y close-on-content-click v-if="!personal && !disabled">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  class="mr-4"
                                  icon
                                  small
                              >
                                <v-icon size="20" color="success">mdi-dots-vertical</v-icon>
                              </v-btn>
                            </template>
                            <v-list dense nav>
                              <v-list-item dense @click="addProfitRow($event, item.id)">
                                <v-list-item-icon>
                                  <v-icon size="20">mdi-plus</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Додати підпорядкований елемент</v-list-item-title>
                              </v-list-item>
                              <v-divider></v-divider>
                              <v-list-item dense @click="editProfitRow(item)">
                                <v-list-item-icon>
                                  <v-icon size="20">mdi-pencil</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Змінити</v-list-item-title>
                              </v-list-item>
                              <v-divider></v-divider>
                              <v-list-item dense @click="deleteProfit(item.id)">
                                <v-list-item-icon>
                                  <v-icon size="20">mdi-delete</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Вилучити</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </div>
                        <div :class="item.parent_id ? 'pl-6' : ''">{{ item.name }}</div>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" xl="6" class="py-0">
                        <v-row>
                          <v-col cols="3">
                            <div>{{ item.date | formatDate }}</div>
                          </v-col>
                          <v-col cols="3">
                            <div>{{ item.repeat }}</div>
                          </v-col>
                          <v-col cols="3">
                            <div>{{ item.sum | formatNumber | formatNumber }}</div>
                          </v-col>
                          <v-col cols="3">
                            <div>{{ getMonthSum(item) | formatNumber }}</div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-card>
                  </template>
                  <template v-else>
                    <v-card :key="item.id"
                            class="d-flex flex-wrap flex-row align-center row-card-body font-weight-medium"
                            elevation="0"
                            :class="personal ? 'py-2' : ''"
                    >
                      <v-col cols="12" sm="6" md="6" xl="6" class="py-0 d-flex align-center">
                        <div>
                          <v-menu offset-y close-on-content-click v-if="!personal">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  class="mr-4"
                                  icon
                                  small
                              >
                                <v-icon size="20" color="success">mdi-dots-vertical</v-icon>
                              </v-btn>
                            </template>
                            <v-list dense nav>
                              <v-list-item dense @click="addProfitRow($event, item.id)">
                                <v-list-item-icon>
                                  <v-icon size="20">mdi-plus</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Додати підпорядкований елемент</v-list-item-title>
                              </v-list-item>
                              <v-divider></v-divider>
                              <v-list-item dense @click="editProfitRow(item)">
                                <v-list-item-icon>
                                  <v-icon size="20">mdi-pencil</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Змінити</v-list-item-title>
                              </v-list-item>
                              <v-divider></v-divider>
                              <v-list-item dense @click="deleteProfit(item.id)">
                                <v-list-item-icon>
                                  <v-icon size="20">mdi-delete</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Вилучити</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </div>
                        <div :class="item.parent_id ? 'pl-6' : ''">{{ item.name }}</div>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" xl="6" class="py-0">
                        <v-row>
                          <v-col cols="3">
                            <div>{{ item.date | formatDate }}</div>
                          </v-col>
                          <v-col cols="3">
                            <div>{{ item.repeat }}</div>
                          </v-col>
                          <v-col cols="3">
                            <div>{{ item.sum | formatNumber | formatNumber }}</div>
                          </v-col>
                          <v-col cols="3">
                            <div>{{ getMonthSum(item) | formatNumber }}</div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-card>
                    <template v-for="children in item.children">
                      <v-card :key="children.id"
                              class="d-flex flex-wrap flex-row align-center row-card-body-children"
                              elevation="0"
                              :class="personal ? 'py-1' : ''"
                      >
                        <v-col cols="12" sm="6" md="6" xl="6" class="py-0 d-flex align-center">
                          <div>
                            <v-menu offset-y close-on-content-click v-if="!personal">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    class="mr-4"
                                    icon
                                    small
                                >
                                  <v-icon size="20" color="success">mdi-dots-vertical</v-icon>
                                </v-btn>
                              </template>
                              <v-list dense nav>
                                <v-list-item dense @click="editProfitRow(children)">
                                  <v-list-item-icon>
                                    <v-icon size="20">mdi-pencil</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title>Змінити</v-list-item-title>
                                </v-list-item>
                                <v-divider></v-divider>
                                <v-list-item dense @click="deleteProfit(children.id)">
                                  <v-list-item-icon>
                                    <v-icon size="20">mdi-delete</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title>Вилучити</v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </div>
                          <div class="pl-7">{{ children.name }}</div>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" xl="6" class="py-0">
                          <v-row>
                            <v-col cols="3">
                              <div>{{ children.date | formatDate }}</div>
                            </v-col>
                            <v-col cols="3">
                              <div>{{ children.repeat }}</div>
                            </v-col>
                            <v-col cols="3">
                              <div>{{ children.sum | formatNumber | formatNumber }}</div>
                            </v-col>
                            <v-col cols="3">
                              <div>{{ getMonthSum(children) | formatNumber }}</div>
                            </v-col>
                          </v-row>
                        </v-col>

                      </v-card>
                    </template>
                  </template>

                </template>

                <v-card class="d-flex flex-wrap flex-row row-card-footer" elevation="0">
                  <v-col cols="12" sm="6" md="6" xl="6" class="py-2">
                    <div class="text-right"></div>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" xl="6" class="py-2">
                    <v-row>
                      <v-col cols="3"></v-col>
                      <v-col cols="3" class="text-right">Всього:</v-col>
                      <v-col cols="3">
                        <div>{{ getTotal('profit') | formatToFixed | formatNumber }}</div>
                      </v-col>
                      <v-col cols="3">
                        <div>{{ getMonthSumTotal('profit') | formatNumber }}</div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-btn outlined depressed color="success" class="mt-2" small @click="addOutcomeRow" v-if="!personal">Додати рядок</v-btn>
            <v-row class="mt-1">
              <v-col cols="12">
                <v-card class="d-flex flex-wrap flex-row row-card-header" elevation="0">
                  <v-col cols="12" sm="6" md="6" xl="6">
                    <div>Назва статі</div>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" xl="6">
                    <v-row>
                      <v-col cols="3">
                        <div>Початок дії</div>
                      </v-col>
                      <v-col cols="3">
                        <div>К-сть повторів</div>
                      </v-col>
                      <v-col cols="3">
                        <div>Вартість</div>
                      </v-col>
                      <v-col cols="3">
                        <div>Сума в місяць</div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-card>

                <template
                    v-for="item in outcome"
                >
                  <template v-if="!item.children.length">
                    <v-card :key="item.id"
                            class="d-flex flex-wrap flex-row align-center row-card-body"
                            elevation="0"
                            :class="personal ? 'py-2' : ''"
                    >
                      <v-col cols="12" sm="6" md="6" xl="6" class="py-0 d-flex align-center">
                        <div>
                          <v-menu offset-y close-on-content-click v-if="!personal">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  class="mr-4"
                                  icon
                                  small
                              >
                                <v-icon size="20" color="success">mdi-dots-vertical</v-icon>
                              </v-btn>
                            </template>
                            <v-list dense nav>
                              <v-list-item dense @click="addOutcomeRow($event, item.id)">
                                <v-list-item-icon>
                                  <v-icon size="20">mdi-plus</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Додати підпорядкований елемент</v-list-item-title>
                              </v-list-item>
                              <v-divider></v-divider>
                              <v-list-item dense @click="editOutcomeRow(item)">
                                <v-list-item-icon>
                                  <v-icon size="20">mdi-pencil</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Змінити</v-list-item-title>
                              </v-list-item>
                              <v-divider></v-divider>
                              <v-list-item dense @click="deleteOutcome(item.id)">
                                <v-list-item-icon>
                                  <v-icon size="20">mdi-delete</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Вилучити</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </div>
                        <div :class="item.parent_id ? 'pl-6' : ''">{{ item.name }}</div>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" xl="6" class="py-0">
                        <v-row>
                          <v-col cols="3">
                            <div>{{ item.date | formatDate }}</div>
                          </v-col>
                          <v-col cols="3">
                            <div>{{ item.repeat }}</div>
                          </v-col>
                          <v-col cols="3">
                            <div>{{ item.sum | formatNumber | formatNumber }}</div>
                          </v-col>
                          <v-col cols="3">
                            <div>{{ getMonthSum(item) | formatNumber }}</div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-card>
                  </template>
                  <template v-else>
                    <v-card :key="item.id"
                            class="d-flex flex-wrap flex-row align-center row-card-body font-weight-medium"
                            elevation="0"
                            :class="personal ? 'py-2' : ''"
                    >
                      <v-col cols="12" sm="6" md="6" xl="6" class="py-0 d-flex align-center">
                        <div>
                          <v-menu offset-y close-on-content-click v-if="!personal">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  class="mr-4"
                                  icon
                                  small
                              >
                                <v-icon size="20" color="success">mdi-dots-vertical</v-icon>
                              </v-btn>
                            </template>
                            <v-list dense nav>
                              <v-list-item dense @click="addOutcomeRow($event, item.id)">
                                <v-list-item-icon>
                                  <v-icon size="20">mdi-plus</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Додати підпорядкований елемент</v-list-item-title>
                              </v-list-item>
                              <v-divider></v-divider>
                              <v-list-item dense @click="editOutcomeRow(item)">
                                <v-list-item-icon>
                                  <v-icon size="20">mdi-pencil</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Змінити</v-list-item-title>
                              </v-list-item>
                              <v-divider></v-divider>
                              <v-list-item dense @click="deleteOutcome(item.id)">
                                <v-list-item-icon>
                                  <v-icon size="20">mdi-delete</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Вилучити</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </div>
                        <div :class="item.parent_id ? 'pl-6' : ''">{{ item.name }}</div>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" xl="6" class="py-0">
                        <v-row>
                          <v-col cols="3">
                            <div>{{ item.date | formatDate }}</div>
                          </v-col>
                          <v-col cols="3">
                            <div>{{ item.repeat }}</div>
                          </v-col>
                          <v-col cols="3">
                            <div>{{ item.sum | formatNumber | formatNumber }}</div>
                          </v-col>
                          <v-col cols="3">
                            <div>{{ getMonthSum(item) | formatNumber }}</div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-card>
                    <template v-for="children in item.children">
                      <v-card :key="children.id"
                              class="d-flex flex-wrap flex-row align-center row-card-body-children"
                              elevation="0"
                              :class="personal ? 'py-1' : ''"
                      >
                        <v-col cols="12" sm="6" md="6" xl="6" class="py-0 d-flex align-center">
                          <div>
                            <v-menu offset-y close-on-content-click v-if="!personal">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    class="mr-4"
                                    icon
                                    small
                                >
                                  <v-icon size="20" color="success">mdi-dots-vertical</v-icon>
                                </v-btn>
                              </template>
                              <v-list dense nav>
                                <v-list-item dense @click="editOutcomeRow(children)">
                                  <v-list-item-icon>
                                    <v-icon size="20">mdi-pencil</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title>Змінити</v-list-item-title>
                                </v-list-item>
                                <v-divider></v-divider>
                                <v-list-item dense @click="deleteOutcome(children.id)">
                                  <v-list-item-icon>
                                    <v-icon size="20">mdi-delete</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title>Вилучити</v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </div>
                          <div class="pl-7">{{ children.name }}</div>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" xl="6" class="py-0">
                          <v-row>
                            <v-col cols="3">
                              <div>{{ children.date | formatDate }}</div>
                            </v-col>
                            <v-col cols="3">
                              <div>{{ children.repeat }}</div>
                            </v-col>
                            <v-col cols="3">
                              <div>{{ children.sum | formatNumber | formatNumber }}</div>
                            </v-col>
                            <v-col cols="3">
                              <div>{{ getMonthSum(children) | formatNumber }}</div>
                            </v-col>
                          </v-row>
                        </v-col>

                      </v-card>
                    </template>
                  </template>

                </template>

                <v-card class="d-flex flex-wrap flex-row row-card-footer" elevation="0">
                  <v-col cols="12" sm="6" md="6" xl="6" class="py-2">
                    <div class="text-right"></div>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" xl="6" class="py-2">
                    <v-row>
                      <v-col cols="3"></v-col>
                      <v-col cols="3" class="text-right">Всього:</v-col>
                      <v-col cols="3">
                        <div>{{ getTotal('outcome') | formatToFixed | formatNumber }}</div>
                      </v-col>
                      <v-col cols="3">
                        <div>{{ getMonthSumTotal('outcome') | formatNumber }}</div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import {diffMonths, getEndOfYear} from "@/utils/icons"
import estimateAPI from "@/utils/axios/estimate"
import {ALERT_SHOW} from "@/store/actions/alert";
import {mapGetters} from "vuex";
import {QUESTION_SHOW} from "@/store/actions/question";

const variant = {
  income_new: 'Створення елемента дохідної частини',
  outcome_new: 'Створення елемента витратної частини',
  income_edit: 'Редагування елемента дохідної частини',
  outcome_edit: 'Редагування елемента витратної частини',
}

export default {
  name: "Estimate",
  props: {
    personal: {
      type: Boolean,
      default: false
    },
    person_hash: {
      type: String,
      default: ''
    },
      disabled: {
          type: Boolean,
          default: false
      }
  },
  computed: {
    ...mapGetters(
        {
          modalAnswer: 'question_answer',
        }
    ),
    getEditTitle() {
      return variant[`${this.edit_object.type}_${this.edit_object.new ? "new" : "edit"}`] || ''
    },
    editObjectReady() {
      return !!(this.edit_object.name && this.edit_object.date
          && this.edit_object.sum && this.edit_object.repeat)
    }
  },
  data() {
    return {
      years: [],
      current_year: null,
      profit: [],
      outcome: [],
      show_edit: false,
      edit_object: {
        new: true,
        type: null,
        id: null,
        name: null,
        date: null,
        repeat: null,
        sum: null,
        parent_id: null,
        parent_name: ''
      },
      tab: 0
    }
  },
  methods: {
    fillForDefault(income) {
      const payload = {
        text: 'Підтвердіть виконання заповнення стандартними статтями витратної частини. Інсуючі дані за поточний рік будуть очищені. Продовжити?',
        accept_button: true,
        id: 'fill-default',
        payload: {income: income}
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    cleatCurrentYear(income) {
      const payload = {
        text: `Підтвердіть очищення ${income ? 'дохідної частини' : 'витратної частини'}. Продовжити?`,
        accept_button: true,
        id: 'clear-current-year',
        payload: {income: income}
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    fillForPrevYear() {
      const payload = {
        text: 'Підтвердіть виконання заповнення за даними попереднього року. Інсуючі дані за поточний рік будуть очищені. Продовжити?',
        accept_button: true,
        id: 'fill-prev-year'
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    fetchData(income) {
      const payload = {
        year: this.current_year,
        income: income
      }
      estimateAPI.all(payload)
        .then(response => response.data)
        .then(data => {
          if (income) {
            this.profit = data
          } else {
            this.outcome = data
          }
        })
        .catch(err => {
          const error = err.response.data.detail;
          this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
        })
    },
    round_number(value, precision) {
      return Math.round((value + Number.EPSILON) * Math.pow(10, precision)) / Math.pow(10, precision)
    },
    fillYears() {
      const current_year = new Date().getFullYear()
      const years = []
      for (let i = current_year - 10; i < current_year + 1; i++) {
        years.push(i)
      }
      this.years = years
      this.current_year = current_year
    },
    changeYear(payload) {
      this.current_year = payload
      this.fetchData(true)
      this.fetchData(false)
    },
    clearEditObject() {
      this.edit_object.new = true
      this.edit_object.type = null
      this.edit_object.id = null
      this.edit_object.name = null
      this.edit_object.date = null
      this.edit_object.repeat = null
      this.edit_object.sum = null
      this.edit_object.parent_id = null
      this.edit_object.parent_name = ''
    },
    addProfitRow(event, parent_id) {
      this.edit_object.new = true
      this.edit_object.type = 'income'
      this.edit_object.repeat = 12
      this.edit_object.date = `${this.current_year}-01-01`
      this.edit_object.parent_id = parent_id
      if (parent_id) {
        const row = this.profit.find(item => item.id === parent_id)
        this.edit_object.parent_name = (row || {}).name
      }

      this.show_edit = true
    },
    addOutcomeRow(event, parent_id) {
      this.edit_object.new = true
      this.edit_object.type = 'outcome'
      this.edit_object.repeat = 12
      this.edit_object.date = `${this.current_year}-01-01`
      this.edit_object.parent_id = parent_id
      if (parent_id) {
        const row = this.outcome.find(item => item.id === parent_id)
        this.edit_object.parent_name = (row || {}).name
      }

      this.show_edit = true
    },
    editProfitRow(payload) {
      this.edit_object.new = false
      this.edit_object.type = 'income'
      this.edit_object.date = payload.date
      this.edit_object.repeat = payload.repeat
      this.edit_object.name = payload.name
      this.edit_object.sum = payload.sum
      this.edit_object.id = payload.id
      this.edit_object.parent_id = payload.parent_id

      if (payload.parent_id) {
        const row = this.profit.find(item => item.id === payload.parent_id)
        this.edit_object.parent_name = (row || {}).name
      }

      this.show_edit = true
    },
    editOutcomeRow(payload) {
      this.edit_object.new = false
      this.edit_object.type = 'outcome'
      this.edit_object.date = payload.date
      this.edit_object.repeat = payload.repeat
      this.edit_object.name = payload.name
      this.edit_object.sum = payload.sum
      this.edit_object.id = payload.id
      this.edit_object.parent_id = payload.parent_id

      if (payload.parent_id) {
        const row = this.outcome.find(item => item.id === payload.parent_id)
        this.edit_object.parent_name = (row || {}).name
      }

      this.show_edit = true
    },
    saveChanges() {
      if (this.edit_object.type === 'income') {
        this.crudProfitRow()
        this.closeModal()
      }
      if (this.edit_object.type === 'outcome') {
        this.crudOutcomeRow()
        this.closeModal()
      }

    },
    getMonthSum(item) {
        const sum = item.sum
        const date_start = item.date
        const date_end = getEndOfYear(date_start)
        const repeat = diffMonths(date_end, date_start)

        if (repeat && sum) {
          return this.round_number(sum / repeat, 2)
        } else {
          return 0
        }
    },
    getMonthSumTotal(table) {
      return this[table].reduce((acc, item) => {
        const sum = item.sum
        const date_start = item.date
        const date_end = getEndOfYear(date_start)
        const repeat = diffMonths(date_end, date_start)

        if (repeat && sum) {
          return acc + this.round_number(sum / repeat, 2)
        } else {
          return acc
        }
      }, 0)
    },
    getTotal(table, col='sum') {
      return this[table].reduce((acc, item) => {
        return acc + item[col]
      }, 0)
    },
    crudProfitRow() {
      if (!this.editObjectReady) {
        this.$store.commit(ALERT_SHOW, {text: 'Заповніть усі необхідні поля', color: 'error'})
        return
      }
      if (this.edit_object.new) {
        // const parent = this.profit.find(i => i.id === (this.edit_object.parent_id || -1))
        const payload = {
          name: this.edit_object.name,
          date: this.edit_object.date,
          repeat: this.edit_object.repeat,
          sum: this.edit_object.sum,
          parent_id: this.edit_object.parent_id,
          year: this.current_year,
          income: true
        }

        estimateAPI.create(payload)
          .then(response => response.data)
          .then(data => {
            const parent = this.profit.find(i => i.id === (data.parent_id || -1))
            if (parent) {
              parent.children.push(data)
            } else {
              this.profit.push(data)
            }
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
          })

      } else {
        const item_id = this.edit_object.id
        const payload = {
          name: this.edit_object.name,
          date: this.edit_object.date,
          repeat: this.edit_object.repeat,
          sum: this.edit_object.sum,
          parent_id: this.edit_object.parent_id,
          year: this.current_year,
          income: true,
          id: this.edit_object.id
        }
        estimateAPI.update(payload)
            .then(response => response.data)
            .then(data => {
              const row = this.profit.find(item => item.id === item_id)
              if (row) {
                row.date = data.date
                row.name = data.name
                row.repeat = data.repeat
                row.sum = data.sum
                row.children = data.children
              }
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
            })
      }
    },
    crudOutcomeRow() {
      if (!this.editObjectReady) {
        this.$store.commit(ALERT_SHOW, {text: 'Заповніть усі необхідні поля', color: 'error'})
        return
      }
      if (this.edit_object.new) {
        const payload = {
          name: this.edit_object.name,
          date: this.edit_object.date,
          repeat: this.edit_object.repeat,
          sum: this.edit_object.sum,
          parent_id: this.edit_object.parent_id,
          year: this.current_year,
          income: false
        }

        estimateAPI.create(payload)
            .then(response => response.data)
            .then(data => {
              const parent = this.outcome.find(i => i.id === (data.parent_id || -1))
              if (parent) {
                parent.children.push(data)
              } else {
                this.outcome.push(data)
              }
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
            })

      } else {
        const item_id = this.edit_object.id
        const payload = {
          name: this.edit_object.name,
          date: this.edit_object.date,
          repeat: this.edit_object.repeat,
          sum: this.edit_object.sum,
          parent_id: this.edit_object.parent_id,
          year: this.current_year,
          income: false,
          id: this.edit_object.id
        }
        estimateAPI.update(payload)
            .then(response => response.data)
            .then(data => {
              const row = this.outcome.find(item => item.id === item_id)
              if (row) {
                row.date = data.date
                row.name = data.name
                row.repeat = data.repeat
                row.sum = data.sum
                row.children = data.children
              }
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
            })
      }
    },
    deleteProfit(item_id) {
      if (item_id) {
        estimateAPI.remove(item_id)
          .then(response => response.data)
          .then(data => {
            if (data.parent_id) {
              const row = this.profit.find(i => i.id === data.parent_id)
              if (row) {
                const c = row.children.find(i => i.id === data.id)
                if (c) {
                  row.children.splice(row.children.indexOf(c), 1)
                }
              }
            } else {
              const row = this.profit.find(i => i.id === data.id)
              if (row) {
                this.profit.splice(this.profit.indexOf(row), 1)
              }
            }
          })
          // .catch(err => {
          //   const error = err.response.data.detail;
          //   this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
          // })
      }
    },
    deleteOutcome(item_id) {
      if (item_id) {
        estimateAPI.remove(item_id)
            .then(response => response.data)
            .then(data => {
              if (data.parent_id) {
                const row = this.outcome.find(i => i.id === data.parent_id)
                if (row) {
                  const c = row.children.find(i => i.id === data.id)
                  if (c) {
                    row.children.splice(row.children.indexOf(c), 1)
                  }
                }
              } else {
                const row = this.outcome.find(i => i.id === data.id)
                if (row) {
                  this.outcome.splice(this.outcome.indexOf(row), 1)
                }
              }
            })
        .catch(err => {
          const error = err.response.data.detail;
          this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
        })
      }
    },
    closeModal() {
      this.show_edit = false
      this.clearEditObject()
    }
  },
  created() {
    this.fillYears()
    this.fetchData(true)
    this.fetchData(false)
  },
  watch: {
    person_hash: {
      immediate: true,
      handler(payload) {
        if (payload) {
          this.fillYears()
          this.fetchData(true)
          this.fetchData(false)
        }
      }
    },
    modalAnswer: {
      immediate: true,
      handler(payload) {
        if (payload.id === 'fill-default') {
          if (payload.answer) {
            estimateAPI.fill_from_default({year: this.current_year, income: payload.payload.income})
              .then(response => response.data)
              .then(data => {
                if (payload.payload.income) {
                  this.profit = data
                  this.tab = 0
                } else {
                  this.outcome = data
                  this.tab = 1
                }
                this.$store.commit(ALERT_SHOW, { text: 'Заповнено успішно', color: 'success' })
              })
              .catch(err => {
                const error = err.response.data.detail;
                this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
              })
          }
        }
        if (payload.id === 'fill-prev-year') {
          if (payload.answer) {
            estimateAPI.fill_from_prev_year({year: this.current_year})
                .then(() => {
                  this.fetchData(true)
                  this.fetchData(false)
                  this.$store.commit(ALERT_SHOW, { text: 'Заповнено успішно', color: 'success' })
                  this.tab = 0
                })
                .catch(err => {
                  const error = err.response.data.detail;
                  this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                })
          }
        }
        if (payload.id === 'clear-current-year') {
          if (payload.answer) {
            estimateAPI.clear_current_year({year: this.current_year, income: payload.payload.income})
                .then(() => {
                  if (payload.payload.income) {
                    this.profit = []
                    this.tab = 0
                  } else {
                    this.outcome = []
                    this.tab = 1
                  }
                  this.$store.commit(ALERT_SHOW, { text: 'Очищено успішно', color: 'success' })
                })
                .catch(err => {
                  const error = err.response.data.detail;
                  this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                })
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.main-card-content {
  .main-card-title {
    font-size: 1.2rem;
    color: #3d3d3d;
  }

  .main-card-value {
    font-size: 1.54rem;
    font-weight: 500;
    color: #5e5e5e;
  }
}

.row-card-header {
  font-size: .86rem;
  font-weight: 500;
  background-color: #f3f3f3;
  margin-bottom: 8px;
}

.row-card-body {
  font-size: .88rem;
  background-color: #f7f7f7;
  margin-top: 2px;
  margin-bottom: 2px;
  line-height: 1.1rem;
}

.row-card-body-children {
  font-size: .86rem;
  color: #4f4f4f;
  background-color: #fffde7;
}

.row-card-footer {
  font-size: .86rem;
  font-weight: 500;
  background-color: #f3f3f3;
  margin-top: 8px;
}

</style>